import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import ProjectTemplate from '../../../../templates/project-template';

function QatarBuyProjectTemplatePage(props){

  const data = useStaticQuery(graphql`
  query QatarBuyProjectQuery {
    glstrapi {
      articles(where: {all_menus: {Primary_URL: "view-for-our-latest-projects"}} publicationState: LIVE) {
        id
        Alias
        Content_Heading_Text
        Content
        Layout
        Meta_Title
        Meta_Description
        Page_CSS_Class
        Custom_CSS_Class
        Pagename
        Publish
        Select_Popular_Search
        imagetransforms
        Header_Banner_Image {
          id
          url
          alternativeText
        }
        Header_Banner_Video
        Play_Button
        popular_search {
          Module_Name
          ModuleType {
            ... on GLSTRAPI_ComponentModulesContentBlock {
              id
              Title
              Content
            }
          }
        }

        Add_Modules {

          __typename

          ... on GLSTRAPI_ComponentModulesShowInsights {
            id
            Show_Insights
            Filter_by_Tag
          }

          ... on GLSTRAPI_ComponentModulesContentBlock {
            id
            Title
            Content
          }

          ... on GLSTRAPI_ComponentModulesCollections {
            id
            Select_Collection
          }
        }
      }
    }
  }`)

  return (
    <ProjectTemplate 
      location={props.location}
      GQLPage = {data.glstrapi?.articles[0]}
      GQLModules = {data.glstrapi?.articles[0]?.Add_Modules}
    />
  )
}

export default QatarBuyProjectTemplatePage